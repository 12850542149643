import React, {Component}                        from "react";
import {withStyles, Drawer, Typography, Divider} from "@material-ui/core/";
import {bindActionCreators}                      from "redux";
import moment                                    from "moment";
import "moment/locale/fr-ca";
import {connect}                                 from "react-redux";
import * as Actions                              from "./store/actions/index";
import * as NotificationActions                  from "app/main/store/common/actions";
import withReducer                               from "app/store/withReducer";
import reducer                                   from "./store/reducers";
import Logout                                    from "app/main/genericComponents/Logout";
import FeedbackDialog                            from "app/main/components/auth/feedback/FeedbackDialog";
import CommonCombineReducer                      from "app/main/store/common/reducers";
import ShowNotifications                         from "app/main/genericComponents/notification/ShowNotifications";
import history                                   from "@history";
import {Button}                                  from "@material-ui/core";
import {FontAwesomeIcon}                         from "@fortawesome/react-fontawesome";
import {faFileCheck}                             from "@fortawesome/pro-solid-svg-icons";

const styles = theme => ({
    rootQuickPanel: {
        width  : 300,
        padding: 24,
    },

    rootNotificationPanel: {
        [theme.breakpoints.down("md")]: {
            width: "80%",
        },
        [theme.breakpoints.up("md")]  : {
            width: 900,
        },
        padding                       : 24,
    },
    feedBackButton       : {
        marginBottom: 10,
    },
});

class QuickPanel extends Component {

    render() {
        const {classes, state, toggleQuickPanel} = this.props;
        moment.locale("fr");
        return (
            <React.Fragment>

                <Drawer
                    classes={{paper: classes.rootQuickPanel}}
                    open={state}
                    anchor="right"
                    onClose={() => toggleQuickPanel(false)}
                >
                    <div className="mb-0">
                        <Typography className="mb-12 text-18" color="textSecondary">
                            {moment().format("dddd DD MMMM")}
                        </Typography>
                        <Typography className="mb-12 text-18" color="textSecondary">
                            {moment().format("h:mm:ss a")}
                        </Typography>
                    </div>

                    <Divider className={this.props.classes.feedBackButton}/>
                    <div className={"flex flex-col justify-between h-full"}>
                        <div>

                            <FeedbackDialog/>
                            <Button className={"mt-8"} fullWidth variant="contained" color={"primary"} onClick={() => history.push("/conseils")}>
                                Conseils
                            </Button>
                            <a href={'https://docs.google.com/forms/d/e/1FAIpQLSe5FlyhNQ6xPXU4WNMG28HEjFnocIvjqlNWNu2-Nk8aCD7wUQ/viewform'}>
                                <Button className={"mt-8"} fullWidth variant="contained" color={"primary"}>
                                    <FontAwesomeIcon icon={faFileCheck}/> Formulaire rétroaction
                                </Button>
                            </a>
                        </div>

                        <div>
                            <Typography align={"center"}>{"Version : " + process.env.REACT_APP_VERSION}</Typography>
                            <Divider className={"my-4"}/>
                            <Logout/>
                        </div>
                    </div>
                </Drawer>

                <Drawer
                    classes={{paper: classes.rootNotificationPanel}}
                    open={this.props.panelIsOpen}
                    anchor="right"
                    onClose={() => this.props.toggleNotificationPanel(false)}
                >
                    <div className={"flex flex-col justify-between h-full"}>
                        <ShowNotifications/>
                    </div>
                </Drawer>

            </React.Fragment>

        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
                                  toggleQuickPanel       : Actions.toggleQuickPanel,
                                  toggleNotificationPanel: NotificationActions.toggleNotificationPanel,
                              }, dispatch);
}

function mapStateToProps({quickPanel, CommonCombineReducer}) {
    return {
        state      : quickPanel.state,
        panelIsOpen: CommonCombineReducer.ReducersNotification.panelIsOpen,
    };
}

export default withReducer("quickPanel", reducer)(withReducer("CommonCombineReducer", CommonCombineReducer)(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(QuickPanel))));
