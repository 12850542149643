import React             from "react";
import {Typography}      from "@material-ui/core";
import history           from "@history";
import NotificationBase  from "app/main/genericComponents/notification/NotificationBase";

export function InvalidNotification(props) {
    console.log(props);
    return (
        <NotificationBase
            content={<Typography className={"align-middle"}>
                {"Une erreur est arrivé avec la notification " + props.type + ". Veuillez avertir le département de programmation. "}
            </Typography>}
            onRedirect={() => history.push("/dashboard")}
            notification={props}
        />
    );
}


