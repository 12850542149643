import React from "react";

export const BusinessServiceConfig = {
    settings: {
        layout: {
            config: {},
        }
    },
    routes  : [
        {
            path       : "/services-affaires/plans/:id",
            component  : React.lazy(() => import("app/main/components/services/businessService/businessPlan/ShowBusinessPlan")),
            permissions: ["display_business_services"],
        },
        {
            path       : "/services-affaires/plans",
            component  : React.lazy(() => import("app/main/components/services/businessService/businessPlan/ShowBusinessPlans")),
            permissions: ["display_business_services"],
        },
    ]
};