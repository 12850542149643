import React, {Component}                    from "react";
import {isApiCallSuccessful, showApiMessage} from "app/services/axios/genericAction";
import ControlledDialog                      from "app/main/genericComponents/dialog/ControlledDialog";

export default class FormDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading     : false,
            dialogIsOpen: false,
        };
    }

    handleSubmit() {
        this.setState({loading: true});
        this.props.handleSubmit().then((response) => {
            showApiMessage(response, this.props.messageError404);
            if (isApiCallSuccessful(response)) {
                this.props.handleUpdate(response);
                this.handleClose();
            } else {
                if (JSON.parse(response.request.response).errors) {
                    this.props.context.setState({errors: JSON.parse(response.request.response).errors});
                }
            }
            this.setState({loading: false})
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.dialogIsOpen !== prevState.dialogIsOpen) {
            this.props.context.setState(this.props.initialState);
            this.props.onOpenDialog();
        }
    }

    handleClose() {
        this.props.handleClose();
        this.setState({dialogIsOpen: false})
    }

    onOpenDialog(buttonProps){
        if(!buttonProps.disabled){
            this.setState({dialogIsOpen: true})
        }
    }

    render() {
        return (
            <>
                <div onClick={() => this.onOpenDialog(this.props.renderOpenButton.props)}>
                    {this.props.renderOpenButton}
                </div>
                <ControlledDialog
                    {...this.props}
                    open={this.state.dialogIsOpen}
                    handleClose={() => this.handleClose()}
                    handleSubmit={() => this.handleSubmit()}
                    handleUpdate={(response) => this.props.handleUpdate(response)}
                    disabledSubmitButton={this.state.loading || this.props.disabledSubmitButton}
                />
            </>

        );
    }
}

FormDialog.defaultProps = {
    messageError404: "",
    handleUpdate   : () => {
    },
    onOpenDialog   : () => {
    },
    handleClose :()=>{

    }
};