import {Component} from "react";
import {connect}   from "react-redux";

class HasPermissions extends Component {

    needAllPermission(requiredPermissions, userPermissions) {
        let hasAllPermission = true;
        requiredPermissions.forEach(permission => {
            if (!userPermissions.includes(permission)) {
                hasAllPermission = false
            }
        })
        return hasAllPermission;
    }

    needOnePermission(requiredPermissions, userPermissions) {
        let hasOnePermission = false;
        requiredPermissions.forEach(permission => {
            if (userPermissions.includes(permission)) {
                hasOnePermission = true
            }
        })
        return hasOnePermission;
    }

    needMissingPermission(requiredPermissions, userPermissions) {
        let MissMissingPermission = true;
        requiredPermissions.forEach(permission => {
            if (userPermissions.includes(permission)) {
                MissMissingPermission = false;
            }
        })
        return MissMissingPermission;
    }

    render() {
        const requiredPermission = this.props.requiredPermissions;
        const userPermissionCodes = this.props.user.data.permissions.map(permission => permission.code);
        switch (this.props.requirementType) {
            case 'all':
                if (this.needAllPermission(requiredPermission, userPermissionCodes)) {
                    return this.props.children
                }
                return null;
            case 'missing' :
                if (this.needMissingPermission(requiredPermission, userPermissionCodes)) {
                    return this.props.children
                }
                return null;
            case 'needOneOf' :
                if (this.needOnePermission(requiredPermission, userPermissionCodes)) {
                    return this.props.children;
                }
                return null;
            default :
                return null;
        }
    }
}

HasPermissions.defaultProps = {
    requirementType: 'all',
    requiredPermissions : [],
};

function mapStateToProps({auth}) {
    return {user: auth.user};
}

export default (connect(mapStateToProps)(HasPermissions));