import axios    from "app/services/axios/CustomAxios";
import {apiUrl} from "app/services/axios/configApi";

export function readAllCells() {
    return axios(apiUrl + "/inventory/cells")
}

export function createCell(cellInformation) {
    return axios.post(apiUrl + "/inventory/cells", {
        "name"       : cellInformation.name,
        "civic_no"   : cellInformation.civic_no,
        "odonym"     : cellInformation.odonym,
        "city"       : cellInformation.city,
        "postal_code": cellInformation.postal_code,
        "location"   : cellInformation.location,
    })
        .catch(error => {
            return error.response;
        });
}

export function saveCell(cellInformation) {
    return axios.put(`${apiUrl + "/inventory/cells/" + cellInformation.id}`, {
        "name"       : cellInformation.name,
        "civic_no"   : cellInformation.civic_no,
        "odonym"     : cellInformation.odonym,
        "city"       : cellInformation.city,
        "postal_code": cellInformation.postal_code,
        "location"   : cellInformation.location,
    })
        .catch(error => {
            return error.response;
        });
}

export function readCell(id) {
    return axios(apiUrl + "/inventory/cells/" + id);
}

export function deleteCell(cellId) {
    return axios.delete(apiUrl + "/inventory/cells/" + cellId)
        .catch(error => {
            return error.response;
        });

}