import React               from "react";
import { Typography }      from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history             from "@history";
import NotificationBase    from "app/main/genericComponents/notification/NotificationBase";
import {faCircleInfo}      from "@fortawesome/pro-solid-svg-icons";

export function TipCreated(props) {

    return (
        <NotificationBase
            content={<Typography className={"align-middle"}>
                <FontAwesomeIcon icon={faCircleInfo}/>
                {" Le conseil " + props.infos.name + " a été créé. "}
            </Typography>}
            onRedirect={() => history.push("/conseils")}
            notification={props}
        />
    );
}


