import React                       from "react";
import { Redirect }                from "react-router-dom";
import { FuseUtils }               from "@fuse/index";
import { LoginConfig }             from "app/main/components/auth/LoginConfig";
import { AuthConfig }              from "app/main/components/auth/AuthConfig";
import { NetworkConfig }           from "app/main/components/network/NetworkConfig";
import { CustomersConfig }         from "app/main/components/customer/CustomersConfig";
import { InventoryConfig }         from "app/main/components/inventory/InventoryConfig";
import { RegionsConfig }           from "app/main/components/region/RegionsConfig";
import { FinanceConfig }           from "app/main/components/finance/FinanceConfig";
import { InternetServiceConfig }   from "app/main/components/services/internetService/InternetServiceConfig";
import { TelephoneServiceConfig }  from "app/main/components/services/phoneService/TelephoneServiceConfig";
import { TelevisionServiceConfig } from "app/main/components/services/televisionService/TelevisionServiceConfig";
import { WorkOrderConfig }         from "app/main/components/workOrder/WorkOrderConfig";
import { BusinessServiceConfig }   from "app/main/components/services/businessService/BusinessServiceConfig";

const routeConfigs = [
    RegionsConfig,
    WorkOrderConfig,
    NetworkConfig,
    LoginConfig,
    AuthConfig,
    CustomersConfig,
    FinanceConfig,
    TelephoneServiceConfig,
    InternetServiceConfig,
    TelevisionServiceConfig,
    InventoryConfig,
    BusinessServiceConfig
];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path     : "/",
        exact    : true,
        component: () => <Redirect to="/login"/>,
    },
    {
        component: () => <Redirect to="error-404"/>,
    },
];

export default routes;